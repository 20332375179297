import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Container } from '@uc-common/container';
import { Button, ButtonTypeEnum } from '@uc-common/button';

import { Box, Flex } from '../../components/forStartGuide/flex';
import { H2, Paragraph, Step1, Step2, Step3, track } from '../../components/forStartGuide';
import { CommonLayout } from '../../components/Layout/CommonLayout';
import { Navbar, NavbarModesEnum } from '../../components/Navbar';
import { BigHeading, SectionSubtext } from '../../components/Typography';
import { frontmatter as originalFrontmatter } from '../demo';

const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e3e3e3;
  opacity: 0.5;
`;

const StepContainer = ({ active, ...props }) => (
  <Flex py={active ? 60 : 12} maxWidth={980} width="100%" flexDirection="column" {...props} />
);

const SectionTitle = styled(Container)(
  ({ theme: { tablet, desktop } }) => css`
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 1000px;

    ${tablet()} {
      margin-top: 40px;
      margin-bottom: 40px;
    }

    ${desktop()} {
      margin-top: 60px;
      margin-bottom: 60px;
    }
  `
);

const StyledSectionSubtext = styled(SectionSubtext)`
  margin-top: 20px;
`;

export const frontmatter = {
  canonicalUrl: 'https://uploadcare.com/demo/',
  noindex: true,
};

const Demo = ({ location }) => {
  const [image, setImage] = useState();
  const [fileSelected, setFileSelected] = useState(false);

  useEffect(() => {
    typeof window.hj !== 'undefined' &&
      window.hj('trigger', image ? 'marketing_startguide_2' : 'marketing_startguide_1');
  }, [image]);

  return (
    <CommonLayout
      meta={{
        ...originalFrontmatter,
        ...frontmatter,
      }}
      pathName={location.pathname}
    >
      <Navbar mode={NavbarModesEnum.PPC} />
      <main>
        <SectionTitle>
          <BigHeading>A complete file handling infrastructure</BigHeading>
          <StyledSectionSubtext>
            Uploadcare lets you upload, store, and deliver your files to end users. Also, you can
            use tools to transform and optimize images, process videos, convert documents, etc.
            Let&apos;s do some of this without further ado.
          </StyledSectionSubtext>
        </SectionTitle>
        <Flex alignItems="center" flexDirection="column" px={12} pb={[60, 120]}>
          <StepContainer active>
            <Step1
              image={image}
              fileSelected={fileSelected}
              onFileSelect={(file) => {
                track('Image selected');
                setFileSelected(Boolean(file));
              }}
              onUpload={(file) => {
                track('Image uploaded');
                setImage({
                  name: file.name,
                  src: file.cdnUrl,
                  uuid: file.uuid,
                  operations: [],
                  width: file.originalImageInfo.width,
                  height: file.originalImageInfo.height,
                  size: file.size,
                  format: file.originalImageInfo.format,
                });
              }}
              onReset={() => {
                track('Reset image');
                setFileSelected(false);
                setImage(null);
              }}
            />
          </StepContainer>
          <HorizontalLine />
          <StepContainer active={Boolean(image)}>
            <Step2 image={image} />
          </StepContainer>
          <HorizontalLine />
          <StepContainer active={Boolean(image)}>
            <Step3 image={image} />
          </StepContainer>
          <HorizontalLine />
          {image && (
            <StepContainer active={Boolean(image)}>
              <Box pb={[0, 5]}>
                <Box mb={20}>
                  <H2>Ready to get started?</H2>
                </Box>
                <Paragraph>Integrating Uploadcare into your app is easy.</Paragraph>
                <Flex>
                  <Button
                    as="a"
                    href="/accounts/signup/"
                    onClick={() => track('Sign up')}
                    type={ButtonTypeEnum.PRIMARY}
                  >
                    Sign up
                  </Button>
                </Flex>
              </Box>
            </StepContainer>
          )}
        </Flex>
      </main>
    </CommonLayout>
  );
};

export default Demo;
